/* .banner-container {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.form-header {
    width: 100%;
}

.piechartsBox_area {
    width: 100%;
} */

.sections-row {
    display: flex;
    justify-content: space-between;
}

.section {
    padding: 20px;
    margin: 0 10px; /* Space between sections */
    border-radius: 5px;
}

.image-preview {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.remove-image {
    font-size: 35px;
    cursor: pointer;
    color: #b40822;
    margin-left: 10px;
}